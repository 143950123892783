export default {
  namespaced: true,
  state: () => ({
    slaConfigItems: [],
    slaConfigByBrandId: []
  }),
  getters: {
    slaConfigItems: (state) => state.slaConfigItems,
    slaConfigItemByBrandId: (state) => {
      return state.slaConfigByBrandId;
    }
  },
  actions: {
    fetchSlaConfigItems: async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'sla-configuration', method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_SLA_CONFIG_ITEMS', rows);
    },
    fetchSlaConfigItemById: async ({ dispatch }, id) => {
      const res = await dispatch('apiRequest', { path: `sla-configuration/${id}`, method: 'GET' }, { root: true });
      return res.json();
    },
    createItem: async ({ dispatch }, item) => {
      const params = {
        path: 'sla-configuration',
        method: 'POST',
        body: JSON.stringify(item)
      };

      await dispatch('apiRequest', params, { root: true });
    },
    updateItem: async ({ dispatch }, item) => {
      const params = {
        path: `sla-configuration/${item.id}`,
        method: 'PUT',
        body: JSON.stringify(item)
      };

      await dispatch('apiRequest', params, { root: true });
    },
    uploadSlaRules: async ({ dispatch }, data) => {
      const params = {
        path: 'sla-configuration/upload',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': undefined
        }
      };
      const res = await dispatch('apiRequest', params, { root: true });
      return res.json();
    },
    fetchSlaConfigItemByBrandId: async ({ dispatch, commit }, brandId) => {
      try {
        const res = await dispatch('apiRequest', {
          path: `sla-configuration/brand/${brandId}`,
          method: 'GET'
        }, { root: true });
        const row = await res.json();
        commit('SET_SLA_CONFIG_BY_BRAND_ID', row);
      } catch (error) {
        if (error.cause.errorDetails.status === 404) {
          commit('SET_SLA_CONFIG_BY_BRAND_ID', null);
          return;
        }
        throw error;
      }
    },
    createSlaPeakSeasonForBrand: async ({ dispatch }, item) => {
      try {
        const params = {
          path: 'sla-configuration/peak-seasons',
          method: 'POST',
          body: JSON.stringify(item)
        };

        const res = await dispatch('apiRequest', params, { root: true });
        return res.json();
      } catch (error) {
        if (error.cause.errorDetails.status === 404) {
          return;
        }
        throw error;
      }
    },
    deleteSlaPeakSeasonById: async ({ dispatch }, { seasonId, brandId }) => {
      try {
        const params = {
          path: `sla-configuration/peak-seasons/${seasonId}/${brandId}`,
          method: 'DELETE'
        };

        const res = await dispatch('apiRequest', params, { root: true });
        return res.json();
      } catch (error) {
        if (error.cause.errorDetails.status === 404) {
          return;
        }
        throw error;
      }
    },
    fetchSlaPeakSeasonsByBrandId: async ({ dispatch }, brandId) => {
      try {
        const res = await dispatch('apiRequest', {
          path: `sla-configuration/peak-seasons/${brandId}`,
          method: 'GET'
        }, { root: true });

        return await res.json();
      } catch (error) {
        if (error.cause.errorDetails.status === 404) {
          return;
        }
        throw error;
      }
    }
  },
  mutations: {
    SET_SLA_CONFIG_ITEMS: (state, items) => {
      state.slaConfigItems = items;
    },
    SET_SLA_CONFIG_BY_BRAND_ID: (state, item) => {
      state.slaConfigByBrandId = item ? [item] : [];
    }
  }
};
