import { costSubcategoryToRevenueMapping, revenueTypesList } from '@/components/LaborPlanning/constants';
import _ from 'lodash';
import { parseISOLocal, toUsDateFormat } from '@/lib/date';

export default {
  namespaced: true,
  state: () => ({
    laborPlanningRule: {
      hoursPerDay: null,
      osPerHourCost: null,
      agenciesPerHourCost: null,
      defaultPremiumDiscount: null
    },
    laborPlans: [],
    managers: [],
    planners: [],
    availableBrands: {},
  }),
  getters: {
    laborPlanningRule: (state) => state.laborPlanningRule,
    laborPlans: (state) => state.laborPlans,
    managers: (state) => state.managers,
    planners: (state) => state.planners,
    availableBrands: (state) => state.availableBrands,
  },
  actions: {
    fetchRule: async ({ dispatch, commit }) => {
      const params = {
        path: 'planning/rule',
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      commit('SET_RULE', await response.json());
    },
    updateRule: async ({ dispatch, commit }, data) => {
      const params = {
        path: 'planning/rule',
        method: 'PUT',
        body: JSON.stringify(data)
      };
      const response = await dispatch('apiRequest', params, { root: true });
      commit('SET_RULE', await response.json());
    },
    fetchLaborPlans: async ({ dispatch, commit }) => {
      const params = {
        path: 'planning',
        method: 'GET'
      };
      const response = await  dispatch('apiRequest', params, { root: true });
      commit('SET_LABOR_PLANS', await response.json());
    },
    fetchLaborPlanById: async ({ dispatch }, planId) => {
      const params = {
        path: `planning/${planId}`,
        method: 'GET'
      };
      const response = await  dispatch('apiRequest', params, { root: true });
      return response.json();
    },
    submitLaborPlan: async ({ dispatch }, laborPlan) => {
      const params = {
        path: 'planning',
        method: 'POST',
        body: JSON.stringify(laborPlan)
      };
      const result = await  dispatch('apiRequest', params, { root: true });
      const { id } = await result.json();
      return id;
    },
    updateLaborPlan: async ({ dispatch }, { planId, patch }) => {
      const params = {
        path: `planning/${planId}`,
        method: 'PUT',
        body: JSON.stringify(patch)
      };
      const result = await  dispatch('apiRequest', params, { root: true });
      const { id } = await result.json();
      return id;
    },
    fetchManagers: async ({ dispatch, commit }) => {
      const params = {
        path: 'planning/managers',
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      commit('SET_MANAGERS', await response.json());
    },
    fetchParticipant: async ({ dispatch }, id) => {
      const params = {
        path: `planning/participant/${id}`,
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      return await response.json();
    },
    updateParticipant: async ({ dispatch }, data) => {
      const params = {
        path: `planning/participant/${data.id}`,
        method: 'PUT',
        body: JSON.stringify(data)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    fetchAvailableManagers: async ({ dispatch }) => {
      const params = {
        path: 'planning/available-managers',
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      return  await response.json();
    },
    fetchPlanners: async ({ dispatch, commit }) => {
      const params = {
        path: 'planning/planners',
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      commit('SET_PLANNERS', await response.json());
    },
    fetchAvailablePlanners: async ({ dispatch }) => {
      const params = {
        path: 'planning/available-planners',
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      return  await response.json();
    },
    fetchAvailableBrands: async ({ dispatch, commit }) => {
      const params = {
        path: 'planning/available-brands',
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      const brandIds = await response.json();
      commit('SET_AVAILABLE_BRANDS', brandIds);
    },
    changePlanStatus: async ({ dispatch }, { id, status, rejectReason }) => {
      const params = {
        path: `planning/${id}/status`,
        method: 'PUT',
        body: JSON.stringify({ status, rejectReason })
      };

      return dispatch('apiRequest', params, { root: true });
    },
    allowPlanResubmit: async ({ dispatch }, planId) => {
      const params = {
        path: `planning/${planId}/allow-resubmit`,
        method: 'POST',
      };
      return dispatch('apiRequest', params, { root: true });
    },
    fetchPreviousWeekAverages: async ({ dispatch }, { brandId,  weekStartDate }) => {
      const params = {
        path: `planning/units?brandId=${brandId}&week=${weekStartDate}`,
        method: 'GET',
      };

      const response = await dispatch('apiRequest', params, { root: true });
      const responseJson = await response.json();
      const data = _.keyBy(responseJson, (item) => {
        return costSubcategoryToRevenueMapping[item['sub_category']];
      });

      const result = {
        avgActualUnits: {},
        avgForecastUnits: {},
        avgUph: {}
      };

      const formattedWeek = toUsDateFormat(parseISOLocal(weekStartDate));
      const averageNames = _.keys(result);
      _.forEach(averageNames, (averageName) => {
        result[averageName][formattedWeek] = {
          date: formattedWeek
        };
        _.forEach(revenueTypesList, (revenueType) => {
          const roundedValue = _.round(_.get(data, `${revenueType}.${averageName}`, 0));
          result[averageName][formattedWeek][revenueType] = roundedValue;
        });
      });

      return result;
    }
  },
  mutations: {
    'SET_RULE': (state, rule) => {
      state.laborPlanningRule = rule;
    },
    'SET_LABOR_PLANS': (state, items) => {
      state.laborPlans = items;
    },
    'SET_MANAGERS': (state, items) => {
      state.managers = items;
    },
    'SET_PLANNERS': (state, items) => {
      state.planners = items;
    },
    'SET_AVAILABLE_BRANDS': (state, items) => {
      state.availableBrands = _.keyBy(items, 'id');
    }
  }
};
