import _ from 'lodash';

const defaultUPH = {
  id: null,
  expectedPick: null,
  expectedPack: null,
  incentivePick: null,
  incentivePack: null
};

export default {
  namespaced: true,
  state: () => ({
    brandsOverview: [],
    brandNames: {},
    processedBrandNames: {},
    brandExclusions: [],
    brandUph: {
      id: null,
      expectedPick: null,
      expectedPack: null,
      incentivePick: null,
      incentivePack: null
    }
  }),
  getters: {
    brandsOverview: (state) => state.brandsOverview,
    brandExclusions: (state) => state.brandExclusions,
    brandUph: (state) => state.brandUph,
    allBrandNames: (state) => state.brandNames,
    processedBrandNames: (state) => state.processedBrandNames,
  },
  actions: {
    fetchBrandsOverview: async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'brand/overview', method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_BRANDS_OVERVIEW', rows);
    },
    fetchBrandNames:  async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'brand/all-names', method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_ALL_BRAND_NAMES', rows);
    },
    fetchProcessedBrandNames:  async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'brand/processed-names', method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_PROCESSED_BRAND_NAMES', rows);
    },
    fetchBrandExclusions: async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'brand/exclusions', method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_EXCLUSION_BRANDS', rows);
    },
    addExclusion: async ({ dispatch }, brandItem) => {
      const params = {
        path: 'brand/exclusions',
        method: 'POST',
        body: JSON.stringify(brandItem)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    fetchExclusionByCode: async ({ dispatch }, brandCode) => {
      const params = {
        path: `brand/exclusions/${brandCode}`,
        method: 'GET'
      };
      const res = await dispatch('apiRequest', params, { root: true });
      return await res.json();
    },
    updateExclusion: async ({ dispatch }, { brandCode, brandItem }) => {
      const params = {
        path: `brand/exclusions/${brandCode}`,
        method: 'PUT',
        body: JSON.stringify(brandItem)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    deleteExclusion: async ({ dispatch }, brandCode) => {
      const params = {
        path: `brand/exclusions/${brandCode}`,
        method: 'DELETE'
      };
      await dispatch('apiRequest', params, { root: true });
    },
    fetchBrand: async ({ dispatch }, brandId) => {
      const params = {
        path: `brand/${brandId}`,
        method: 'GET'
      };
      const res = await dispatch('apiRequest', params, { root: true });
      return await res.json();
    },
    uploadUphThresholds: async ({ dispatch }, data) => {
      const params = {
        path: 'brand/uph/upload',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': undefined
        }
      };
      const res = await dispatch('apiRequest', params, { root: true });
      return res.json();
    },
    fetchBrandUph: async ({ dispatch, commit }, brandId) => {
      const params = {
        path: `brand/${brandId}/uph`,
        method: 'GET'
      };
      try {
        const res = await dispatch('apiRequest', params, { root: true });
        const rows = await res.json();
        commit('SET_BRAND_UPH', rows);
      } catch (error) {
        if (error.cause.errorDetails.status === 404) {
          commit('SET_BRAND_UPH', { ...defaultUPH });
        }
        else {
          throw error;
        }
      }
    },
    fetchBrandUphById: async ({ dispatch }, uphId) => {
      const params = {
        path: `brand/uph/${uphId}`,
        method: 'GET'
      };
      const res = await dispatch('apiRequest', params, { root: true });
      return await res.json();
    },
    updateBrandUph: async ({ dispatch }, { uphId, dataToSave }) => {
      const params = {
        path: `brand/uph/${uphId}`,
        method: 'PUT',
        body: JSON.stringify(dataToSave)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    addBrandUph: async ({ dispatch }, dataToSave) => {
      const params = {
        path: 'brand/uph',
        method: 'POST',
        body: JSON.stringify(dataToSave)
      };
      await dispatch('apiRequest', params, { root: true });
    },
  },
  mutations: {
    SET_BRANDS_OVERVIEW: (state, items) => {
      state.brandsOverview = items;
    },
    SET_EXCLUSION_BRANDS: (state, items) => {
      state.brandExclusions = items;
    },
    SET_BRAND_UPH: (state, items) => {
      state.brandUph = items;
    },
    SET_ALL_BRAND_NAMES: (state, items) => {
      state.brandNames = _.keyBy(items, 'id');
    },
    SET_PROCESSED_BRAND_NAMES: (state, items) => {
      state.processedBrandNames = _.keyBy(items, 'id');
    },
  }
};
