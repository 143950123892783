import _ from 'lodash';
import { revenueTypesList, revenueTypeToForecastMapping } from '@/components/LaborPlanning/constants';
import { toUsDateFormat } from '@/lib/date';

const defaultRules = {
  id: null,
  client: null,
  ecomLowerBound: null,
  ecomUpperBound: null,
  whWarnBefore: null
};

export default {
  namespaced: true,
  state: () => ({
    forecastsByBrand: [],
    forecastRecipients: [],
    lastUploadForecasts: [],
    forecastRules: {
      id: null,
      client: null,
      ecomLowerBound: null,
      ecomUpperBound: null,
      whWarnBefore: null
    },
    companyItems: {},
    brandForecastDetails: [],
  }),
  getters: {
    forecasts: (state) => state.forecastsByBrand,
    forecastRecipients: (state) => state.forecastRecipients,
    lastUploadForecasts: (state) => state.lastUploadForecasts,
    forecastRules: (state) => state.forecastRules,
    companyItems: (state) => state.companyItems,
    forecastForLaborPlanning: (state) => {
      const result = [];
      _.forEach(revenueTypesList, (revenueType) => {
        const revenueTypeRow = {
          revenueType
        };
        _.forEach(state.brandForecastDetails, (forecastRow) => {
          const keys = revenueTypeToForecastMapping[revenueType];
          const appropriateValues = _.values(_.pick(forecastRow, keys));
          const value = _.find(appropriateValues, (v) => {
            return !!v;
          });
          revenueTypeRow[toUsDateFormat(new Date(forecastRow['date']))] = value || 0;
        });
        result.push(revenueTypeRow);
      });

      return _.keyBy(result, 'revenueType');
    },
  },
  actions: {
    fetchForecastsByBrand: async ({ dispatch, commit }, brandName) => {
      const res = await dispatch('apiRequest', { path: `forecast/${brandName}`, method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_FORECASTS_BY_BRAND', rows);
    },
    fetchForecastRecipients: async ({ dispatch, commit }, brandId) => {
      const res = await dispatch('apiRequest', {
        path: `forecast/${brandId}/recipients`,
        method: 'GET'
      }, { root: true });
      const rows = await res.json();
      commit('SET_FORECASTS_RECIPIENTS', rows);
    },
    changeStatus: async ({ dispatch }, data) => {
      const params = {
        path: `forecast/${data.id}`,
        method: 'PUT',
        body: JSON.stringify(data)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    fetchForecastRecipientById: async ({ dispatch }, id) => {
      const res = await dispatch('apiRequest', { path: `forecast/recipients/${id}`, method: 'GET' }, { root: true });
      return await res.json();
    },
    deleteRecipient: async ({ dispatch }, data) => {
      const params = {
        path: 'forecast/recipients',
        method: 'DELETE',
        body: JSON.stringify(data)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    deleteRecipientByEmail: async ({ dispatch }, email) => {
      const params = {
        path: `forecast/recipients/${email}`,
        method: 'DELETE'
      };
      await dispatch('apiRequest', params, { root: true });
    },
    addRecipient: async ({ dispatch }, data) => {
      const params = {
        path: 'forecast/recipients',
        method: 'POST',
        body: JSON.stringify(data)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    fetchForecastById: async ({ dispatch }, id) => {
      const res = await dispatch('apiRequest', { path: `forecast/${id}/detail`, method: 'GET' }, { root: true });
      return res.json();
    },
    uploadForecastFile: async ({ dispatch }, data) => {
      const params = {
        path: 'forecast/upload',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': undefined
        }
      };
      const res = await dispatch('apiRequest', params, { root: true });
      return res.json();
    },
    uploadForecastThresholdFile: async ({ dispatch }, data) => {
      const params = {
        path: 'forecast/upload/thresholds',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': undefined
        }
      };
      const res = await dispatch('apiRequest', params, { root: true });
      return res.json();
    },
    fetchLastUploadForecasts: async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'forecast/forecasts', method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_LAST_FORECASTS', rows);
    },
    fetchRules: async ({ dispatch, commit }, brandId) => {
      try {
        const res = await dispatch('apiRequest', {
          path: `forecast/${brandId}/rules`,
          method: 'GET'
        }, { root: true });
        const rows = await res.json();
        commit('SET_FORECAST_RULES', rows);
        return rows;
      } catch (error) {
        if (error.cause.errorDetails.status === 404) {
          commit('SET_FORECAST_RULES', { ...defaultRules });
        } else {
          throw error;
        }
      }
    },
    updateForecastRules: async ({ dispatch }, data) => {
      const params = {
        path: 'forecast/rules',
        method: 'PUT',
        body: JSON.stringify(data)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    fetchCompanyItems: async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'forecast/companies', method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_COMPANIES', rows);
    },
    getForecastTemplate: async ({ dispatch }) => {
      const res = await dispatch('apiRequest', { path: 'forecast/template', method: 'GET' }, { root: true });
      const blob = await res.blob();

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'forecast_template.csv';

      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    addForecastRules: async ({ dispatch }, data) => {
      const params = {
        path: 'forecast/rules',
        method: 'POST',
        body: JSON.stringify(data)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    fetchAlertsToClients: async ({ dispatch }) => {
      const params = {
        path: 'forecast/alerts-to-clients',
        method: 'GET'
      };
      const res = await dispatch('apiRequest', params, { root: true });
      return await res.json();
    },
    changeAlertsToClients: async ({ dispatch }, status) => {
      const params = {
        path: 'forecast/alerts-to-clients',
        method: 'PUT',
        body: JSON.stringify(status)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    fetchBrandForecastDetails: async ({ dispatch, commit }, { branId, dateStart, dateEnd }) => {
      commit('SET_BRAND_FORECAST_DETAILS', []);
      const params = {
        path: `forecast/last-actual-forecasts?brandId=${branId}&dateStart=${dateStart}&dateEnd=${dateEnd}`,
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      const data = await response.json();
      commit('SET_BRAND_FORECAST_DETAILS', data);
    },
    downloadForecastFile: async ({ dispatch }, { fileName }) => {
      const params = {
        path: `forecast/download-forecast?fileName=${fileName}`,
        method: 'GET'
      };
      const res = await dispatch('apiRequest', params, { root: true });

      const indexOfTimeStamp = fileName.lastIndexOf('_');
      let filename = fileName.substring(0, indexOfTimeStamp);

      const contentDisposition = res.headers.get('Content-Disposition');
      if (contentDisposition) {
        const matches = contentDisposition.match(/filename="([^"]+)"/);
        if (matches && matches[1]) {
          filename = matches[1];
        }
      }

      const blob = await res.blob();

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;

      a.download = filename;

      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  },
  mutations: {
    SET_FORECASTS_BY_BRAND: (state, items) => {
      state.forecastsByBrand = items;
    },
    SET_FORECASTS_RECIPIENTS: (state, items) => {
      state.forecastRecipients = items;
    },
    SET_LAST_FORECASTS: (state, items) => {
      state.lastUploadForecasts = items;
    },
    SET_FORECAST_RULES: (state, items) => {
      state.forecastRules = items;
    },
    SET_COMPANIES: (state, items) => {
      state.companyItems = _.keyBy(items, 'brandId');
    },
    SET_BRAND_FORECAST_DETAILS: (state, items) => {
      state.brandForecastDetails = items;
    },
  }
};
